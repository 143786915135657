import React from 'react';
import styles from './GlobalNavigationBar.module.css';
import { HyunDaiLogo, LogoutLogo } from 'assets/svgs';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Public GNB Component
 * @returns {JSX.Element}
 */
function GlobalNavigationBar(){
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className={styles.gnb_root}>
            <div className={styles.gnb_body}>
                <div onClick={() => navigate('/dashboard')} className={styles.title_container}>
                    <div>
                        <HyunDaiLogo width={44} height={22} />
                    </div>
                    <span>CASPER STUDIO</span>
                </div>
                <div className={styles.menu_list}>
                    <div 
                        onClick={() => navigate('/dashboard')} 
                        className={`${styles.menu_element} ${location.pathname === '/dashboard' ? styles.active : ''}`}>
                        <span>대시보드</span>
                    </div>
                    <div 
                        onClick={() => navigate('/analyze')} 
                        className={`${styles.menu_element} ${location.pathname === '/analyze' ? styles.active : ''}`}>
                        <span>분석</span>
                    </div>
                    <div 
                        onClick={() => navigate('/user-tracking')} 
                        className={`${styles.menu_element} ${location.pathname === '/user-tracking' ? styles.active : ''}`}>
                        <span>구역 정보</span>
                    </div>
                </div>
                <div className={styles.user_status_container}>
                    <div className={styles.user_info}>
                        <span>CASPER01</span>
                    </div>
                    <div className={styles.vertical_line}></div>
                    <div style={{cursor: 'pointer'}} onClick={(event: React.MouseEvent<HTMLDivElement>) => navigate('/login')}>
                        <LogoutLogo />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlobalNavigationBar;