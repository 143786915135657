import React from 'react';
import { useRecoilState } from 'recoil';
import { alertModalState } from 'stores';
import AlertModal from './AlertModal';


type TProps = {
    children: React.ReactNode
}

/**
 * 어플리케이션 내에서 사용하는 모달들의 상태를 가지고 렌더링 시켜주는 Provider Component
 * @param {Object} props 
 * @param {React.ReactNode} props.children 
 * @returns {JSX.Element}
 */
function ModalProvider({children}: TProps){
    const [alertModal, setAlertModal] = useRecoilState(alertModalState);

    return(
        <>
        {children}
        {alertModal.isOpen ? <AlertModal /> : null}
        </>
    )
}

export default ModalProvider;