const API_URL = process.env.REACT_APP_API_URL;
const API_PORT = process.env.REACT_APP_API_PORT;

export interface TgetUserTracking {
    customerNumber: string;
    placeUid: string;
    startDate: string;
    endDate: string;
}

/**
 * IFIA_0240 - 유저 동선 추적
 * @returns Promise<any>
 */
async function getUserTracking({placeUid, customerNumber, startDate, endDate}: TgetUserTracking){   
    const ACCESSTOKEN = process.env.REACT_APP_TEST_TOKEN;
    if(!ACCESSTOKEN){
        throw new Error('AccessToken is not defined');
    }

    const response = await fetch(`${API_URL}:${API_PORT}/api/v1/places/visits/csmr/moveline?place_uid=${placeUid}&cust_no=${customerNumber}&date_from=${startDate}&date_to=${endDate}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${ACCESSTOKEN}`
        },
      });
    return response.json();
}

export default getUserTracking;