import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { atom, RecoilState } from "recoil";


/**
 * AlertModalState Inferface
 */
interface TAlertModalState {
    isOpen: boolean;
    alertText: string;
    buttonText: string;
}

/**
 * Initialize Modal State
 */
export const initializeAlertModalState = {
    isOpen: false,
    alertText: '',
    buttonText: '',
}

const alertModalState: RecoilState<TAlertModalState> = atom<TAlertModalState>({
    key: 'alertModalState',
    default: initializeAlertModalState
});


export default alertModalState;