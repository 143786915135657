import React, { useEffect, useState } from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { TpostVisitPeriodsAgeResponse } from 'types';
import ChartTooltip from './ChartTooltip';

type TProps = {
    visitorData: TpostVisitPeriodsAgeResponse
}

function BarChart({visitorData}: TProps){
    const [data, setData] = useState<BarDatum[]>([]);
    const transferChartData = () => {
        if(!visitorData) return
        const tempArr: BarDatum[] = []

        visitorData.results.forEach((item) => {
            const parseMonthStr = item.date.substring(4, 6);
            const parseDayStr = item.date.substring(6, 8);
            tempArr.push({
                date: `${parseMonthStr}.${parseDayStr}.`,
                20: item.twenty_count,
                30: item.thirty_count,
                40: item.fourty_count,
                50: item.fifty_count,
            })
        })

        setData([...tempArr]);
    }

    useEffect(() => {
        transferChartData();
    }, [])

    return (
        <ResponsiveBar
        data={data}
        keys={[
            '20',
            '30',
            '40',
            '50',
        ]}
        indexBy="date"
        margin={{ top: 60, right: 30, bottom: 50, left: 50 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={['#003469', 'rgba(0, 52, 105, 0.70)', 'rgba(0, 52, 105, 0.40)', 'rgba(0, 52, 105, 0.15)']}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        tooltip={(e) => {
            return <ChartTooltip title={e.id+'대'} dataText={e.value.toString()} dataType='명'/>
        }}
        enableLabel={false}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: -300,
                translateY: -37,
                itemsSpacing: 0,
                itemWidth: 60,
                itemHeight: 10,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 15,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="bar chart"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
    />
    )
}

export default BarChart;