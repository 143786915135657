import React, { useEffect, useState } from 'react';
import styles from './DashBoard.module.css';
import { GlobalNavigationBar, BarChart, DonutChart, LineChart, HeatMapCalendar, CustomDatePicker } from 'components/public';
import { Summary } from 'components/dashboard';
import { getAverageStayTime, postVisitPeriodsAge, postVisitPeriodsGenders } from 'queries';
import { TpostVisitPeriodsAgeResponse, TpostVisitPeriodsGendersResponse, TgetAverageStayTimeResponse } from 'types';
import { useMutation } from '@tanstack/react-query';
import { convertMilliseconds, convertToMinutes } from 'utils';

const VISITOR_GENDER_DUMP = {
    "place_uid": "TEST_PLACE_UID",
    "daily_or_monthly": "D",
    "results": [
        { date: "20241001", male_count: 3.0, female_count: 2.0 },
        { date: "20241002", male_count: 4.0, female_count: 1.0 },
        { date: "20241003", male_count: 2.0, female_count: 3.0 },
        { date: "20241004", male_count: 5.0, female_count: 0.0 },
        { date: "20241005", male_count: 0.0, female_count: 4.0 },
        { date: "20241006", male_count: 1.0, female_count: 3.0 },
        { date: "20241007", male_count: 2.0, female_count: 2.0 },
        { date: "20241008", male_count: 3.0, female_count: 1.0 },
        { date: "20241009", male_count: 4.0, female_count: 2.0 },
        { date: "20241010", male_count: 0.0, female_count: 5.0 },
        { date: "20241011", male_count: 3.0, female_count: 2.0 },
        { date: "20241012", male_count: 1.0, female_count: 3.0 },
        { date: "20241013", male_count: 4.0, female_count: 1.0 },
        { date: "20241014", male_count: 2.0, female_count: 2.0 },
        { date: "20241015", male_count: 0.0, female_count: 3.0 },
        { date: "20241016", male_count: 3.0, female_count: 1.0 },
        { date: "20241017", male_count: 5.0, female_count: 0.0 },
        { date: "20241018", male_count: 2.0, female_count: 3.0 },
        { date: "20241019", male_count: 1.0, female_count: 4.0 },
        { date: "20241020", male_count: 4.0, female_count: 2.0 },
        { date: "20241021", male_count: 0.0, female_count: 3.0 },
        { date: "20241022", male_count: 3.0, female_count: 1.0 },
        { date: "20241023", male_count: 2.0, female_count: 2.0 },
        { date: "20241024", male_count: 4.0, female_count: 0.0 },
        { date: "20241025", male_count: 1.0, female_count: 3.0 },
        { date: "20241026", male_count: 2.0, female_count: 2.0 },
        { date: "20241027", male_count: 3.0, female_count: 1.0 },
        { date: "20241028", male_count: 0.0, female_count: 4.0 },
        { date: "20241029", male_count: 2.0, female_count: 2.0 },
        { date: "20241030", male_count: 5.0, female_count: 0.0 },
        { date: "20241031", male_count: 1.0, female_count: 3.0 },
    ]
}

const VISITOR_AGE_DUMP = {
    "place_uid": "TEST_PLACE_UID",
    "daily_or_monthly": "D",
    "results": [
        { date: '20241001', below_20_count: 15, twenty_count: 30, thirty_count: 25, fourty_count: 20, fifty_count: 10, over_60_count: 5 },
        { date: '20241002', below_20_count: 10, twenty_count: 28, thirty_count: 22, fourty_count: 18, fifty_count: 12, over_60_count: 6 },
        { date: '20241003', below_20_count: 12, twenty_count: 26, thirty_count: 30, fourty_count: 15, fifty_count: 9, over_60_count: 7 },
        { date: '20241004', below_20_count: 20, twenty_count: 30, thirty_count: 25, fourty_count: 20, fifty_count: 14, over_60_count: 8 },
        { date: '20241005', below_20_count: 18, twenty_count: 27, thirty_count: 20, fourty_count: 16, fifty_count: 11, over_60_count: 5 },
        { date: '20241006', below_20_count: 12, twenty_count: 24, thirty_count: 22, fourty_count: 19, fifty_count: 15, over_60_count: 4 },
        { date: '20241007', below_20_count: 14, twenty_count: 29, thirty_count: 21, fourty_count: 17, fifty_count: 10, over_60_count: 3 },
        { date: '20241008', below_20_count: 16, twenty_count: 31, thirty_count: 29, fourty_count: 22, fifty_count: 13, over_60_count: 6 },
        { date: '20241009', below_20_count: 19, twenty_count: 28, thirty_count: 25, fourty_count: 20, fifty_count: 12, over_60_count: 5 },
        { date: '20241010', below_20_count: 11, twenty_count: 26, thirty_count: 24, fourty_count: 18, fifty_count: 10, over_60_count: 7 },
        { date: '20241011', below_20_count: 15, twenty_count: 32, thirty_count: 27, fourty_count: 21, fifty_count: 13, over_60_count: 4 },
        { date: '20241012', below_20_count: 14, twenty_count: 25, thirty_count: 23, fourty_count: 19, fifty_count: 9, over_60_count: 6 },
        { date: '20241013', below_20_count: 13, twenty_count: 29, thirty_count: 21, fourty_count: 20, fifty_count: 12, over_60_count: 8 },
        { date: '20241014', below_20_count: 10, twenty_count: 30, thirty_count: 26, fourty_count: 22, fifty_count: 11, over_60_count: 5 },
        { date: '20241015', below_20_count: 12, twenty_count: 27, thirty_count: 24, fourty_count: 18, fifty_count: 14, over_60_count: 3 },
        { date: '20241016', below_20_count: 16, twenty_count: 30, thirty_count: 25, fourty_count: 21, fifty_count: 10, over_60_count: 2 },
        { date: '20241017', below_20_count: 14, twenty_count: 28, thirty_count: 23, fourty_count: 19, fifty_count: 12, over_60_count: 6 },
        { date: '20241018', below_20_count: 15, twenty_count: 31, thirty_count: 22, fourty_count: 20, fifty_count: 13, over_60_count: 5 },
        { date: '20241019', below_20_count: 10, twenty_count: 26, thirty_count: 20, fourty_count: 17, fifty_count: 11, over_60_count: 4 },
        { date: '20241020', below_20_count: 12, twenty_count: 29, thirty_count: 25, fourty_count: 21, fifty_count: 14, over_60_count: 3 },
        { date: '20241021', below_20_count: 18, twenty_count: 30, thirty_count: 24, fourty_count: 19, fifty_count: 10, over_60_count: 6 },
        { date: '20241022', below_20_count: 20, twenty_count: 32, thirty_count: 28, fourty_count: 22, fifty_count: 12, over_60_count: 5 },
        { date: '20241023', below_20_count: 15, twenty_count: 27, thirty_count: 23, fourty_count: 20, fifty_count: 13, over_60_count: 4 },
        { date: '20241024', below_20_count: 14, twenty_count: 28, thirty_count: 25, fourty_count: 21, fifty_count: 11, over_60_count: 3 },
        { date: '20241025', below_20_count: 12, twenty_count: 30, thirty_count: 22, fourty_count: 19, fifty_count: 10, over_60_count: 5 },
        { date: '20241026', below_20_count: 10, twenty_count: 29, thirty_count: 27, fourty_count: 20, fifty_count: 12, over_60_count: 6 },
        { date: '20241027', below_20_count: 15, twenty_count: 30, thirty_count: 24, fourty_count: 22, fifty_count: 14, over_60_count: 4 },
        { date: '20241028', below_20_count: 18, twenty_count: 31, thirty_count: 25, fourty_count: 21, fifty_count: 13, over_60_count: 5 },
        { date: '20241029', below_20_count: 14, twenty_count: 28, thirty_count: 23, fourty_count: 19, fifty_count: 9, over_60_count: 3 },
        { date: '20241030', below_20_count: 12, twenty_count: 26, thirty_count: 20, fourty_count: 18, fifty_count: 21, over_60_count: 6 },
        { date: '20241031', below_20_count: 10, twenty_count: 30, thirty_count: 24, fourty_count: 20, fifty_count: 12, over_60_count: 5 },
    ]
}

const RESIDENCE_TIME_DUMP = {
    place_uid: "TEST_PLACE_UID",
    daily_or_monthly: "D",
    results: [
        { date: "20241101", average_residence_time: 3600000.0 }, // 1시간
        { date: "20241102", average_residence_time: 2700000.0 }, // 45분
        { date: "20241103", average_residence_time: 1800000.0 }, // 30분
        { date: "20241104", average_residence_time: 5400000.0 }, // 1시간 30분
        { date: "20241105", average_residence_time: 7200000.0 }, // 2시간
        { date: "20241106", average_residence_time: 1500000.0 }, // 25분
        { date: "20241107", average_residence_time: 4200000.0 }, // 1시간 10분
        { date: "20241108", average_residence_time: 3000000.0 }, // 50분
        { date: "20241109", average_residence_time: 3600000.0 }, // 1시간
        { date: "20241110", average_residence_time: 2400000.0 }, // 40분
        { date: "20241111", average_residence_time: 3600000.0 }, // 1시간
        { date: "20241112", average_residence_time: 600000.0 },  // 10분
        { date: "20241113", average_residence_time: 1800000.0 }, // 30분
        { date: "20241114", average_residence_time: 3600000.0 }, // 1시간
        { date: "20241115", average_residence_time: 4800000.0 }, // 1시간 20분
        { date: "20241116", average_residence_time: 3000000.0 }, // 50분
        { date: "20241117", average_residence_time: 3600000.0 }, // 1시간
        { date: "20241118", average_residence_time: 2400000.0 }, // 40분
        { date: "20241119", average_residence_time: 5400000.0 }, // 1시간 30분
        { date: "20241120", average_residence_time: 7200000.0 }, // 2시간
        { date: "20241121", average_residence_time: 1500000.0 }, // 25분
        { date: "20241122", average_residence_time: 4200000.0 }, // 1시간 10분
        { date: "20241123", average_residence_time: 3000000.0 }, // 50분
        { date: "20241124", average_residence_time: 3600000.0 }, // 1시간
        { date: "20241125", average_residence_time: 2400000.0 }, // 40분
        { date: "20241126", average_residence_time: 600000.0 },  // 10분
        { date: "20241127", average_residence_time: 1800000.0 }, // 30분
        { date: "20241128", average_residence_time: 3600000.0 }, // 1시간
        { date: "20241129", average_residence_time: 4800000.0 }, // 1시간 20분
        { date: "20241130", average_residence_time: 3000000.0 }, // 50분
    ]
};

function DashBoard(){
    const date = new Date();
    const [dateState, setDateState] = useState<Date | null>(date);
    const [totalVisitor, setTotalVisitor] = useState(0); 
    const [bestVisitAge, setBestVisitAge] = useState<string | null>(null);
    const [bestVisitGender, setBestVisitGender] = useState<string | null>(null); 
    const [averageResidenceTime, setAverageResidenceTime] = useState<number | null>(null); 

    const {data: requestVisitorGendersCountData, mutate: requestVisitorGendersCount, isPending: requestVisitorGendersCountIsPending} = useMutation({
        mutationFn: postVisitPeriodsGenders,
        onSuccess(data: TpostVisitPeriodsGendersResponse, variables, context) {
            if(data.results.length === 0){
                requestVisitorGendersCount({
                    "place_uid": "TEST_PLACE_UID",
                    "daily_or_monthly": "D",
                    "start_date":"2024-10-01", 
                    "end_date":"2024-10-31",
                })
            }
        },
        onError(error, variables, context) {
            console.error(error)
        },
    })

    const {data: requestVisitorAgesCountData, mutate: requestVisitorAgesCount, isPending: requestVisitorAgesCountIsPending} = useMutation({
        mutationFn: postVisitPeriodsAge,
        onSuccess(data: TpostVisitPeriodsAgeResponse, variables, context) {
            if(data.results.length === 0){
                requestVisitorAgesCount({
                    "place_uid": "TEST_PLACE_UID",
                    "daily_or_monthly": "D",
                    "start_date":"2024-10-01", 
                    "end_date":"2024-10-31",
                })
            }
        },
        onError(error, variables, context) {
            console.error(error)
        },
    })

    const {data: requestAverageStayTimeData, mutate: requestAverageStayTime, isPending: requestAverageStayTimeIsPending } = useMutation({
        mutationFn: getAverageStayTime,
        onSuccess(data: TgetAverageStayTimeResponse, variables, context) {
            if(data.results.length === 0){
                requestAverageStayTime({
                    "place_uid": "TEST_PLACE_UID",
                    "daily_or_monthly": "D", // "M" 일때 월별
                    "start_date":"2024-10-01", // "M" 일때 일 생략 필수
                    "end_date":"2024-10-31" // "M" 일때 일 생략 필수
                })
            }
        },
        onError(error, variables, context) {
            console.error(error);
        },
    })

    useEffect(() => {
        const started = `${dateState ? dateState.getFullYear() : '0000'}-${(dateState ? dateState.getMonth() + 1 : 0) < 10 ? '0' + (dateState ? dateState.getMonth() + 1: 0) : (dateState ? dateState.getMonth() + 1: 0)}-01`
        const ended = `${dateState ? dateState.getFullYear(): '0000'}-${(dateState ? dateState.getMonth() + 1 : 0) < 10 ? '0' + (dateState ? dateState.getMonth() + 1: 0) : (dateState ? dateState.getMonth() + 1 : 0)}-31`

        requestVisitorGendersCount({
            "place_uid": "TEST_PLACE_UID",
            "daily_or_monthly": "D",
            "start_date":started, 
            "end_date":ended,
        })

        requestVisitorAgesCount({
            "place_uid": "TEST_PLACE_UID",
            "daily_or_monthly": "D",
            "start_date":started, 
            "end_date":ended,
        })

        requestAverageStayTime({
            "place_uid": "TEST_PLACE_UID",
            "daily_or_monthly": "D", // "M" 일때 월별
            "start_date":started, 
            "end_date":ended,
        })
    }, [dateState])

    // DashBoard Card Component Init Data
    useEffect(() => {
        // total visitor
        let maleCount = 0;
        let femaleCount = 0;
        VISITOR_GENDER_DUMP.results.forEach((item, index) => {
            if(item.male_count > 1){
                maleCount = maleCount + item.male_count
            }
            if(item.female_count > 1){
                femaleCount = femaleCount + item.female_count
            }
        })
        setTotalVisitor(maleCount + femaleCount);

        //best visit gender
        if(maleCount >= femaleCount){
            setBestVisitGender('Male')
        } else {
            setBestVisitGender('Female')
        }

        // average residence time
        let tempTime = 0;
        let indexCount = 0;
        RESIDENCE_TIME_DUMP.results.forEach((item, index) => {
            tempTime += item.average_residence_time;
            indexCount++;
        })
        const {hours,minutes} = convertMilliseconds(tempTime / indexCount);
        setAverageResidenceTime(convertToMinutes(hours, minutes));

        // best visit age
        let allAges: { [key: number]: number } = {
            20: 0,
            30: 0,
            40: 0,
            50: 0,
            60: 0,
        };
        
        VISITOR_AGE_DUMP.results.forEach((item) => {
            allAges[20] += item.twenty_count;
            allAges[30] += item.thirty_count;
            allAges[40] += item.fourty_count;
            allAges[50] += item.fifty_count;
            allAges[60] += item.over_60_count;
        });

        let max = -Infinity; 
        let maxKey: number | null = null; 

        for (const key in allAges) {
            if (allAges[key] > max) {
                max = allAges[key];
                maxKey = Number(key);
            }
        }
        
        setBestVisitAge(maxKey ? maxKey.toString() : null);
    }, [])

    return (
        <>
        <GlobalNavigationBar />
        <main className={styles.main_root}>
            <div className={styles.main_body}>
                <div className={styles.header}>
                    <div className={styles.title_wrapper}>
                        <div className={styles.title}>
                            <span>대시보드</span>
                        </div>
                        
                    </div>
                    <div className={styles.date_container}>
                        <div className={styles.selected_date}>
                            <span><strong>{dateState?.getFullYear()}</strong>년 <strong>{dateState ? (dateState.getMonth() + 1) < 10 ? '0' + (dateState.getMonth() + 1) : (dateState.getMonth() + 1) : ''}</strong>월</span>
                        </div>
                        <div className={styles.select_date}>
                            <div style={{width: 90}}>
                                <CustomDatePicker
                                    date={dateState}
                                    setDate={setDateState}
                                    option={{
                                        placeholderText: `${dateState ? dateState.getFullYear() : ''}`,
                                        mode: 'year'
                                    }}
                                />
                            </div>
                            <div style={{width: 90}}>
                                <CustomDatePicker
                                    date={dateState}
                                    setDate={setDateState}
                                    option={{
                                        placeholderText: `${dateState ? (dateState.getMonth() + 1) < 10 ? '0' + (dateState.getMonth() + 1) : (dateState.getMonth() + 1) : ''}`,
                                        mode: 'month'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.summary_container}>
                    <Summary 
                        totalVisitor={totalVisitor}
                        bestVisitGender={bestVisitGender}
                        bestVisitAge={bestVisitAge}
                        averageResidenceTime={averageResidenceTime}
                    />
                </div>
                <div className={styles.chart_list}>
                    <div className={styles.horizontal_section}>
                        <div className={styles.chart_wrapper}>
                            <span>{'방문자 수 변화 추이 >'}</span>
                            <p>Count / Date</p>
                            {
                                VISITOR_AGE_DUMP ?
                                    <LineChart visitorData={VISITOR_AGE_DUMP} />
                                : null
                            }
                        </div>
                    </div>
                    <div className={styles.horizontal_section}>
                        <div className={styles.chart_wrapper}>
                            <span>{'연령대별 방문자 분포 >'}</span>
                            <p>Count / Date</p>
                            {
                                VISITOR_AGE_DUMP ?
                                    <BarChart visitorData={VISITOR_AGE_DUMP} />
                                : null
                            }
                        </div>
                    </div>
                    <div className={styles.horizontal_section}>
                        <div className={styles.chart_wrapper}>
                            <span>{'성별별 방문자 비율 >'}</span>
                            {
                                VISITOR_GENDER_DUMP ?
                                    <DonutChart visitorData={VISITOR_GENDER_DUMP} />
                                : null
                            }
                        </div>
                        <div style={{
                            paddingTop: 58,
                            paddingLeft: 20,
                            display: 'flex',
                            justifyContent: 'center',
                        }} className={styles.chart_wrapper}>
                            <span>{'일자별 평균 체류 시간 >'}</span>
                            <p>Date / Minute</p>
                            {
                                RESIDENCE_TIME_DUMP ?
                                    <HeatMapCalendar calendarData={RESIDENCE_TIME_DUMP} />
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    )
}

export default DashBoard;