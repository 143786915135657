import React from 'react';
import styles from './ChartTooltip.module.css';
import { TooltipBody } from 'assets/svgs';

type Tprops = {
    title: string;
    dataText: string;
    dataType?: string;
}

/**
 * Public Tooltip Box
 * @param {Object} props
 * @param {string} props.title - 툴팁 제목
 * @param {string} props.dataText - 데이터
 * @param {string} props.dataType - 데이터의 타입 
 * @returns {JSX.Element}
 */
function ChartTooltip({title, dataText, dataType}: Tprops){

    return (
        
        <div className={styles.chart_tooltip_root}>
            <div className={styles.body}>
                <div>
                    <span>{title}</span>
                </div>
                <div>
                    <span>{dataText}</span>
                    {dataType ? <p>{dataType}</p> : null}
                </div>
            </div>
        </div>
    )
}

export default ChartTooltip;