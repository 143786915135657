import React from 'react';
import styles from './Summary.module.css';
import { VisitorLogo, ResidenceTimeLogo } from 'assets/svgs';

type Tprops = {
    totalVisitor: number | null,
    bestVisitGender: string | null,
    averageResidenceTime: number | null,
    bestVisitAge: string | null,
}

function Summary({totalVisitor, bestVisitGender, averageResidenceTime, bestVisitAge}: Tprops){
    return (
        <div className={styles.summary_root}>
            <div className={styles.summary_element}>
                <div className={styles.box_title}>
                    <span>{'방문자 수 >'}</span>
                </div>
                <div className={styles.between_wrapper}>
                    <div>
                        <VisitorLogo />
                    </div>
                    <div className={styles.count_box}>
                        <span>{totalVisitor ? totalVisitor : '00'}</span>
                        <span>명</span>
                    </div>
                </div>
            </div>
            <div className={styles.vertical_line}></div>
            <div className={styles.summary_element}>
                <div className={styles.box_title}>
                    <span>{'평균 체류 시간 >'}</span>
                </div>
                <div className={styles.between_wrapper}>
                    <div>
                        <ResidenceTimeLogo />
                    </div>
                    <div className={styles.count_box}>
                        <span>{averageResidenceTime ? averageResidenceTime : '00'}</span>
                        <span>분</span>
                    </div>
                </div>
            </div>
            <div className={styles.vertical_line}></div>
            <div className={styles.summary_element}>
                <div className={styles.box_title}>
                    <span>{'주요 고객 군 >'}</span>
                </div>
                <div style={{marginTop: '30px'}} className={styles.between_wrapper}>
                    <div className={styles.vertical_title_wrapper}>
                        <div className={styles.title_text}>
                            <span>연령</span>
                        </div>
                        <div className={styles.count_box}>
                            <span>{bestVisitAge ? bestVisitAge : '00'}</span>
                            <span>대</span>
                        </div>
                    </div>
                    <div style={{width: '108px'}} className={styles.vertical_title_wrapper}>
                        <div className={styles.title_text}>
                            <span>성별</span>
                        </div>
                        <div style={{marginTop: '12px'}} className={styles.gender_text}>
                            <span>{bestVisitGender ? bestVisitGender : 'None'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary;