const API_URL = process.env.REACT_APP_API_URL;
const API_PORT = process.env.REACT_APP_API_PORT;

export interface TputRssi {
    place_uid: string;
    cust_no: string;
    beacon_uid: string;
    beacon_major_no: number;
    beacon_minor_no: number;
    rssi: number;
    time: string;
}

async function putRssi({place_uid, cust_no, beacon_uid, beacon_major_no, beacon_minor_no, rssi, time}: TputRssi){   
    const ACCESSTOKEN = process.env.REACT_APP_TEST_TOKEN;
    if(!ACCESSTOKEN){
        throw new Error('AccessToken is not defined');
    }
    const data = {
        "place_uid": place_uid,
        "cust_no": cust_no,
        "beacon_uid": beacon_uid,
        "beacon_major_no": beacon_minor_no,
        "beacon_minor_no": beacon_minor_no, // 1 고정
        "rssi": rssi, // RSSI 값
        "time": time //"2024-08-19T05:23:51.486+00:00"
    }
    const response = await fetch(`${API_URL}:${API_PORT}/api/v1/rssi/put`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${ACCESSTOKEN}`
        },
        body: JSON.stringify(data),
      });
    return response.json();
}

export default putRssi;