import React, { useEffect, useState } from 'react';
import {ResponsivePie} from '@nivo/pie';
import { TpostVisitPeriodsGendersResponse } from 'types';
import ChartTooltip from './ChartTooltip';

interface TchartData {
    id: string;
    label: string;
    value: number;
    color: string;
}

type TProps = {
    visitorData: TpostVisitPeriodsGendersResponse
}

/**
 * Dashboard Page 성별별 방문자 Donut Chart
 * @param {Object} props 
 * @param {TpostVisitPeriodsGendersResponse} props.visitorData
 * @returns {JSX.Element}
 */
function DonutChart({visitorData}: TProps){
    const [data, setData] = useState<TchartData[]>([]);
    const [maleCount, setMaleCount] = useState<number>(0);
    const [femaleCount, setfemaleCount] = useState<number>(0);
    const transferChartData = () => {
        if(!visitorData) return
        const maleData:{id: string, label: string, value: number, color: string, fill: string} = {
            id: 'male',
            label: 'Male',
            value: 0,
            color: "#E3DCD4",
            fill: '#E3DCD4',
        }

        const femaleData:{id: string, label: string, value: number, color: string, fill: string} = {
            id: 'female',
            label: 'Female',
            value: 0,
            color: "#003469",
            fill: '#003469',
        }

        visitorData.results.forEach((item) => {
            if(item.male_count > 0){
                maleData.value++
            }
            if(item.female_count > 0){
                femaleData.value++
            }
        })
        setMaleCount(maleData.value);
        setfemaleCount(femaleData.value);
        const totalValue = maleData.value + femaleData.value;
        maleData.value = Math.round(maleData.value / totalValue * 100)
        femaleData.value = Math.round(femaleData.value / totalValue * 100)

        setData([maleData, femaleData]);
    }

    useEffect(() => {
        transferChartData();
    }, [])
    
    return (
        <ResponsivePie
        data={data}
        margin={{ top: 30, right: 30, bottom: 30, left: 200 }}
        startAngle={-1}
        innerRadius={0.5}
        activeOuterRadiusOffset={3}
        colors={['#E3DCD4', '#003469']}
        borderColor='color'
        arcLinkLabelsSkipAngle={29}
        arcLinkLabelsTextOffset={0}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsOffset={-1}
        arcLinkLabelsDiagonalLength={11}
        arcLinkLabelsStraightLength={19}
        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
        enableArcLabels={true}
        arcLabel="formattedValue"
        valueFormat={v => `${v}%`}
        arcLabelsSkipAngle={4}
        arcLabelsRadiusOffset={2.5}
        tooltip={(e) => {
            const isMale = e.datum.data.label === "Male" ? true : false
            return <ChartTooltip title={isMale ? '남성' : '여성'} dataText={isMale ? maleCount.toString() : femaleCount.toString()} dataType='명'/>
        }}
        legends={[
            {
                anchor: 'bottom-left',
                direction: 'row',
                justify: false,
                translateX: -160,
                translateY: -30,
                itemsSpacing: 0,
                itemWidth: 81,
                itemHeight: 10,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 20,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
    )
}

export default DonutChart;