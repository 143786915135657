import React from 'react';
import './initialize.css';
import './global.css';
import { Route, Routes } from 'react-router-dom';
import { Analyze, DashBoard, Login, NotFound, UserTracking } from 'routes';

function App() {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/dashboard' element={<DashBoard />} />
      <Route path='/analyze' element={<Analyze />} />
      <Route path='/user-tracking' element={<UserTracking />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default App;
