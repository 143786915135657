import React, { useEffect, useState } from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { TpostVisitPeriodsGendersResponse } from 'types';
import ChartTooltip from './ChartTooltip';

type TProps = {
    visitorData: TpostVisitPeriodsGendersResponse
}

/**
 * Analyze Page 성별별 방문자 분포 차트
 * @param {Object} props
 * @param {TpostVisitPeriodsGendersResponse} props.visitorData
 * @returns {JSX.Element}
 */
function GenderBarChart({visitorData}: TProps){
    const [data, setData] = useState<BarDatum[]>([]);
    const transferChartData = () => {
        if(!visitorData) return
        const tempArr: BarDatum[] = []

        visitorData.results.forEach((item) => {
            const parseMonthStr = item.date.substring(4, 6);
            const parseDayStr = item.date.substring(6, 8);
            tempArr.push({
                date: `${parseMonthStr}.${parseDayStr}.`,
                Male: item.male_count,
                Female: item.female_count,
            })
        })

        setData([...tempArr]);
    }

    useEffect(() => {
        transferChartData();
    }, [])

    return (
        <ResponsiveBar
        data={data}
        keys={[
            'Female',
            'Male',
        ]}
        indexBy="date"
        margin={{ top: 60, right: 30, bottom: 50, left: 50 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={['#9B6D54','#D8CCC5']}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        tooltip={(e) => {
            return <ChartTooltip title={e.id.toString()} dataText={e.value.toString()} dataType='명'/>
        }}
        enableLabel={false}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: -350,
                translateY: -37,
                itemsSpacing: 0,
                itemWidth: 80,
                itemHeight: 10,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 15,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="bar chart"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
    />
    )
}

export default GenderBarChart;