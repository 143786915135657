import React, { useEffect } from 'react';
import styles from './NotFound.module.css';
import { useNavigate } from 'react-router-dom';

function NotFound(){
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/dashboard');
    }, [])

    return (
        <main className={styles.main_root}>

        </main>
    )
}

export default NotFound;