const API_URL = process.env.REACT_APP_API_URL;
const API_PORT = process.env.REACT_APP_API_PORT;

export interface TpostSignIn {
    id: string;
    password: string;
}

async function postSignIn({id, password}: TpostSignIn){       
    const data = {
        id,
        password
    }

    const response = await fetch(`${API_URL}:${API_PORT}/token/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      
    return response.json();
}

export default postSignIn;