import { useMutation } from '@tanstack/react-query';
import { postSignIn } from 'queries';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ACCESS_TOKEN_KEYNAME = 'AccessToken';
const REFRESH_TOKEN_KEYNAME = 'RefreshToken';
const USER_ID = 'uid';

function useAuth(){
    const navigate = useNavigate();
    const {mutateAsync: requestSignIn, isPending: requestSignInIsPending} = useMutation({
        mutationFn: postSignIn,
        onSuccess(data, variables, context) {
            console.log('sign in :', data)
        },
        onError(error, variables, context) {
            console.error(error);
        },
    })
    const [isAuthentication, setIsAuthentication] = useState(false);
    
    const getLocalToken = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEYNAME);
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEYNAME);

        return {
            accessToken,
            refreshToken,
        }
    }

    useEffect(() => {
        const tokens = getLocalToken();
        if(tokens.accessToken && tokens.refreshToken){
            setIsAuthentication(true);
        }
    }, [])
    

    const signIn = async ({id, password}: {id: string, password: string}) => {
        const result = await requestSignIn({
            id, password
        })

        localStorage.setItem(USER_ID, id);
        localStorage.setItem(ACCESS_TOKEN_KEYNAME, result.accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEYNAME, result.refreshToken);

        setIsAuthentication(true);

        return result;
    }

    const signOut = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEYNAME);
        localStorage.removeItem(REFRESH_TOKEN_KEYNAME);
        localStorage.removeItem(USER_ID);

        navigate('/login');
    }

    return {
        isAuthentication,
        signIn,
        signOut,
        getLocalToken,
    }
}

export default useAuth;