import React from 'react';
import styles from './LoadingComponent.module.css';

function LoadingComponent(){

    return (
        <div className={styles.loading_root}>
            <div className={styles.background}></div>
            <div className={styles.loading_body}>
                <span className={styles.loader}></span>
            </div>
        </div>
    )
}

export default LoadingComponent;