import React, { useEffect, useState } from 'react';
import styles from './VisitorResidenceTimeTable.module.css';
import { TzoneResidenceTimeResponse } from 'types';
import { convertMilliseconds, convertToMinutes } from 'utils';

type Tprops = {
    startDate: Date | null,
    endDate: Date | null,
    residenceTimeData: TzoneResidenceTimeResponse
}
const allZones = ['WELCOME ZONE', 'ENTRANCE ZONE', 'CASPER ZONE', 'CASPER CONNECT ZONE', 'EV ZONE', 'COUNSELING ZONE']
function VisitorResidenceTimeTable({startDate, endDate, residenceTimeData}: Tprops){
    const started = `${startDate ? startDate.getFullYear() : '0000'}.${(startDate ? startDate.getMonth() + 1 : 0) < 10 ? '0' + (startDate ? startDate.getMonth() + 1: 0) : (startDate ? startDate.getMonth() + 1: 0)}.${(startDate ? startDate.getDate(): 0) < 10 ? '0' + (startDate ? startDate.getDate(): 0) : (startDate ? startDate.getDate(): 0)}.`
    const ended = `${endDate ? endDate.getFullYear(): '0000'}.${(endDate ? endDate.getMonth() + 1 : 0) < 10 ? '0' + (endDate ? endDate.getMonth() + 1: 0) : (endDate ? endDate.getMonth() + 1 : 0)}.${(endDate ? endDate.getDate() : 0) < 10 ? '0' + (endDate ? endDate.getDate() : 0) : (endDate ? endDate.getDate(): 0)}.`
    const [empty, setEmpty] = useState<string[]>([]);
    
    /**
     * 두 가지 Array를 비교해서 Unique한 값만 담은 String 배열을 return
     * @param arr1 string[]
     * @param arr2 string[]
     * @returns string[]
     */
    const getUniqueValues = (arr1: string[], arr2: string[]) => {
        const set1 = new Set(arr1);
        const set2 = new Set(arr2);
        const uniqueInArr1 = [...set1].filter(item => !set2.has(item));
        const uniqueInArr2 = [...set2].filter(item => !set1.has(item));
        return [...uniqueInArr1, ...uniqueInArr2];
    }

    useEffect(() => {
        const renderZones: string[] = []
        residenceTimeData.results.forEach((item, index) => {
            renderZones.push(item.zone_name);
        })
        const set = getUniqueValues(renderZones, allZones);
        setEmpty(set);
    }, [residenceTimeData.results])

    return (
        <table className={styles.table_root}>
            <thead>
                <tr>
                    <th style={{width: 250}} className={styles.table_th_head} >ZONE / DATE</th>
                    <th className={styles.table_th_head} >{started === '0000.00.00.' || ended === '0000.00.00.' ? '-' : `${started} ~ ${ended}`}</th>
                </tr>
            </thead>
            <tbody>
                {
                    residenceTimeData ? 
                    residenceTimeData.results.map((item, index) => {
                        const {hours, minutes} = convertMilliseconds(item.average_zone_residence_time);
                        const convertedMinuteData = convertToMinutes(hours, minutes);
                        return (
                            <tr key={item.zone_uid}>
                                <td style={{width: 250}} className={styles.table_td_head} >{item.zone_name}</td>
                                <td className={styles.table_td} ><strong>{convertedMinuteData} </strong>분</td>
                            </tr>
                        )
                    })
                    : null
                }
                {
                    empty ? 
                    empty.map((item, index) => {
                        return (
                            <tr className={styles.empty} key={item}>
                                <td style={{width: 250}} className={styles.table_td_head} >{item}</td>
                                <td className={styles.table_td} >- 분</td>
                            </tr>
                        )
                    })
                    : null
                }
            </tbody>
        </table>
    )
}

export default VisitorResidenceTimeTable;