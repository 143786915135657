import React, { ChangeEventHandler } from 'react';
import styles from './InputBox.module.css';

type Tprops = {
    type?: React.HTMLInputTypeAttribute
    value: string | number | readonly string[] | undefined;
    onChangeHandler: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    maxLength?: number;
}

function InputBox({type, value, onChangeHandler, placeholder, maxLength}: Tprops){
    return <input maxLength={maxLength} placeholder={placeholder} className={styles.inputbox_root} type={type} value={value} onChange={onChangeHandler} />
}

export default InputBox;