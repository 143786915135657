/**
 * Change the default date format to 'YYYY-MM-DD'
 * @param {Date} date - new Date()
 * @returns 
 */
const convertDateFormat = (date: Date) => {
    const convertedDate = `${date ? date.getFullYear() : '0000'}-${(date ? date.getMonth() + 1 : 0) < 10 ? '0' + (date ? date.getMonth() + 1: 0) : (date ? date.getMonth() + 1: 0)}-${(date ? date.getDate() : 0) < 10 ? '0' + (date ? date.getDate(): 0) : (date ? date.getDate() : 0)}`
    return convertedDate
}

export default convertDateFormat;