import React, { useEffect, useState } from 'react';
import styles from './HeatMapCalendar.module.css';
import { TgetAverageStayTimeResponse } from 'types';
import ChartTooltip from './ChartTooltip';
import { convertMilliseconds } from 'utils';

type Tprops = {
    calendarData: TgetAverageStayTimeResponse
}

/**
 * Dashboard Page 히트맵 캘린더
 * @param {Object} props
 * @param {TgetAverageStayTimeResponse} props.calendarData
 * @returns {JSX.Element}
 */
function HeatMapCalendar({calendarData}: Tprops){
    const [calcDate, setCalcDate] = useState<number[]| null>(null);
    const [firstDateIndex, setFirstDateIndex] = useState<number | null>(null);
    const [lastDateIndex, setLastDateIndex] = useState<number | null>(null);
    const [tooltip, setTooltip] = useState<{id: string} | null>(null);

    const calcCalendarData = () => {
        const date = new Date();
        const thisYear = date.getFullYear();
        const thisMonth = date.getMonth();

        const prevLast = new Date(thisYear, thisMonth, 0);
        const thisLast = new Date(thisYear, thisMonth + 1, 0);

        const PLDate = prevLast.getDate(); // 지난 일 마지막 일
        const PLDay = prevLast.getDay(); // 지난 달 마지막 요일

        const TLDate = thisLast.getDate(); // 이번 달 마지막 날
        const TLDay = thisLast.getDay(); // 이번 달 마지막 요일

        const prevDates = [];
        const thisDates = Array.from(Array(TLDate + 1).keys()).slice(1); // 이번 달 초부터 말일까지
        const nextDates = [];

        if(PLDay !== 6){
            for (let i = 0; i < PLDay + 1; i++){
                prevDates.unshift(PLDate - i); // 지난 달 날짜
            }
        }
        for (let i = 1; i < 7 - TLDay; i++){
            nextDates.push(i) // 다음 달 날짜
        }

        const dates = prevDates.concat(thisDates, nextDates) // 이전 달, 이번 달, 다음 달 데이터
        setFirstDateIndex(dates.indexOf(1)) // 이번 달 1일의 인덱스
        setLastDateIndex(dates.lastIndexOf(TLDate)) // 이번 달 마지막 날의 인덱스
        setCalcDate(dates);
    }

    useEffect(() => {
        calcCalendarData();
    }, [])

    return (
        <div className={styles.heatmap_calendar_root}>
            <div className={styles.calendar_body}>
                {
                    calcDate && firstDateIndex && lastDateIndex ? calcDate.map((calcDateItem, calcDateIndex) => {
                        const isOtherDay = calcDateIndex >= firstDateIndex && calcDateIndex < lastDateIndex + 1 ? false : true;
                        if(isOtherDay){
                            return(
                                <div 
                                    key={'date-' + calcDateItem + isOtherDay} 
                                    className={`${styles.date_element} ${styles.other_date}`}>
                                </div>
                            )
                        } else {
                            let thisClass = undefined;
                            const dataIndex = calcDateItem - 1;
                            const thisCalendarData = calendarData.results[dataIndex]
                            const { hours, minutes, seconds } = convertMilliseconds(thisCalendarData.average_residence_time);
                            const parseMonth = thisCalendarData.date.substring(4,6);
                            const parseDay = thisCalendarData.date.substring(6,8);
                            if(hours * 60 + minutes >= 41){
                                thisClass = styles.this_date_fifth_color
                            } else if(hours * 60 + minutes >= 31){
                                thisClass = styles.this_date_fourth_color
                            } else if(hours * 60 + minutes >= 21){
                                thisClass = styles.this_date_third_color
                            } else if(hours * 60 + minutes >= 11){
                                thisClass = styles.this_date_second_color
                            } else {
                                thisClass = styles.this_date_first_color
                            }
                            return(
                                <div 
                                    key={'date-' + calcDateItem + isOtherDay} 
                                    className={`${styles.date_element} ${thisClass}`}
                                    onMouseOver={() => setTooltip({id: 'date-' + calcDateItem + isOtherDay})}
                                    onMouseOut={() => setTooltip(null)}
                                    >
                                    <div className={styles.date_container}>
                                        <span>{calcDateItem}</span>
                                    </div>
                                    <div className={styles.data_container}>
                                        <span>{hours * 60 + minutes}</span>
                                    </div>
                                    {tooltip && tooltip.id === 'date-' + calcDateItem + isOtherDay ? 
                                    <div style={{position: 'absolute', top: -80}}><ChartTooltip title={parseMonth+'월 '+parseDay+'일'} dataText={(hours * 60 + minutes).toString()} dataType='분' /></div>
                                    : null}
                                </div>
                            )
                        }
                    })
                    : null
                }
            </div>
        </div>
    )
}

export default HeatMapCalendar;