import React, { useEffect, useRef, useState } from 'react';
import styles from './ComboBox.module.css';
import { ArrowDownBlack } from 'assets/svgs';

type Tprops = {
    selectList: string[],
    styleOption?: React.CSSProperties | undefined
    activeIndex: number | null,
    setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>
    placeHolder?: string,
}

function ComboBox({ selectList, styleOption, activeIndex, setActiveIndex, placeHolder}: Tprops){
    const [isSelectMode, setIsSelectMode] = useState(false);
    const comboBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const closeSelectList = (event: MouseEvent) => {
            if(!isSelectMode) return
            if (comboBoxRef.current && !comboBoxRef.current.contains(event.target as Node)) {
                setIsSelectMode(false);
            }
        }
        document.body.addEventListener('mousedown', closeSelectList, true );

        return () => {
            window.removeEventListener('mousedown', closeSelectList, true );
        } 
    }, [isSelectMode])

    return (
        <div ref={comboBoxRef} className={styles.combobox_root}>
            <div 
                style={styleOption ? styleOption : {}} 
                onClick={() => setIsSelectMode(true)} 
                className={styles.selected}>
                <span style={{color: activeIndex !== null ? '#000000' : '#8D939F'}}>{activeIndex === null && placeHolder ? placeHolder : activeIndex !== null ? selectList[activeIndex] : ''}</span>
                <div>
                    <ArrowDownBlack />
                </div>
            </div>
            {
                isSelectMode ?
                    <div onBlur={() => setIsSelectMode(false)} style={styleOption ? styleOption : {}} className={styles.select_list}>
                        {selectList.map((item: string, index: number) => {

                            return (
                                <div 
                                    onClick={() => {
                                        setActiveIndex(index);
                                        setIsSelectMode(false);
                                    }}
                                    key={item + index} 
                                    className={styles.select_list_element}>
                                    <span>{item}</span>
                                </div>
                            )
                        })}
                    </div>
                : null
            }
        </div>
    )
}

export default ComboBox;