import React, { useEffect, useState } from 'react';
import styles from './LineChart.module.css';
import { ResponsiveLine, Serie } from '@nivo/line';
import { TpostVisitPeriodsAgeResponse } from 'types';
import ChartTooltip from './ChartTooltip';


type TProps = {
    visitorData: TpostVisitPeriodsAgeResponse
}

/**
 * 연령대별 방문자 수를 표시하는 LineChart
 * @param {Object} props
 * @param {TpostVisitPeriodsAgeResponse} props.visitorData
 * @returns {JSX.Element}
 */
function LineChart({visitorData}: TProps){
    const [data, setData] = useState<Serie[]>([]);
    const transferChartData = () => {
        if(!visitorData) return

        const tempList: {x: string; y: number;}[] = []

        const tempData: Serie = {
            id: 'visitor',
            data: []
        }

        visitorData.results.forEach((item) => {
            const parseMonthStr = item.date.substring(4, 6);
            const parseDayStr = item.date.substring(6, 8);
            tempList.push({
                x: `${parseMonthStr}.${parseDayStr}.`,
                y: item.twenty_count + item.thirty_count + item.fourty_count + item.fifty_count
            })
        })

        tempData.data = tempList

        setData([tempData]);
    }

    useEffect(() => {
        transferChartData();
    }, [])

    return(
        <ResponsiveLine
            data={data}
            margin={{ top: 60, right: 30, bottom: 50, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            enableGridX={false}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            colors={['#003469']}
            pointSize={8}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={false}
            enableCrosshair={false}
            useMesh={true}
            tooltip={(e) => {
                const splitDateString = e.point.data.x.toString().split('.');
                return <ChartTooltip title={splitDateString[0]+'월 '+splitDateString[1]+'일'} dataText={e.point.data.y.toString()} dataType='명'/>
            }}
        />
    )
}

export default LineChart;