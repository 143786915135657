import React, { useEffect, useState } from 'react';
import styles from './Analyze.module.css';
import { ComboBox, GlobalNavigationBar, CustomDatePicker, BarChart, LineChart, GenderBarChart } from 'components/public';
import { VisitorResidenceTimeTable } from 'components/analyze';
import XLSX from 'xlsx-js-style';
import { convertDateFormat, convertMilliseconds, convertToMinutes } from 'utils';
import { getAverageStayTime, postVisitPeriodsAge, postZoneResidenceTime } from 'queries';
import { TpostVisitPeriodsAgeResponse, TzoneResidenceTimeResponse, TgetAverageStayTimeResponse } from 'types';
import { useMutation } from '@tanstack/react-query';

const VISITOR_GENDER_DUMP = {
    "place_uid": "TEST_PLACE_UID",
    "daily_or_monthly": "D",
    "results": [
        { date: "20241001", male_count: 3.0, female_count: 2.0 },
        { date: "20241002", male_count: 4.0, female_count: 1.0 },
        { date: "20241003", male_count: 2.0, female_count: 3.0 },
        { date: "20241004", male_count: 5.0, female_count: 0.0 },
        { date: "20241005", male_count: 0.0, female_count: 4.0 },
        { date: "20241006", male_count: 1.0, female_count: 3.0 },
        { date: "20241007", male_count: 2.0, female_count: 2.0 },
        { date: "20241008", male_count: 3.0, female_count: 1.0 },
        { date: "20241009", male_count: 4.0, female_count: 2.0 },
        { date: "20241010", male_count: 0.0, female_count: 5.0 },
        { date: "20241011", male_count: 3.0, female_count: 2.0 },
        { date: "20241012", male_count: 1.0, female_count: 3.0 },
        { date: "20241013", male_count: 4.0, female_count: 1.0 },
        { date: "20241014", male_count: 2.0, female_count: 2.0 },
        { date: "20241015", male_count: 0.0, female_count: 3.0 },
        { date: "20241016", male_count: 3.0, female_count: 1.0 },
        { date: "20241017", male_count: 5.0, female_count: 0.0 },
        { date: "20241018", male_count: 2.0, female_count: 3.0 },
        { date: "20241019", male_count: 1.0, female_count: 4.0 },
        { date: "20241020", male_count: 4.0, female_count: 2.0 },
        { date: "20241021", male_count: 0.0, female_count: 3.0 },
        { date: "20241022", male_count: 3.0, female_count: 1.0 },
        { date: "20241023", male_count: 2.0, female_count: 2.0 },
        { date: "20241024", male_count: 4.0, female_count: 0.0 },
        { date: "20241025", male_count: 1.0, female_count: 3.0 },
        { date: "20241026", male_count: 2.0, female_count: 2.0 },
        { date: "20241027", male_count: 3.0, female_count: 1.0 },
        { date: "20241028", male_count: 0.0, female_count: 4.0 },
        { date: "20241029", male_count: 2.0, female_count: 2.0 },
        { date: "20241030", male_count: 5.0, female_count: 0.0 },
        { date: "20241031", male_count: 1.0, female_count: 3.0 },
    ]
}

const RESIDENCE_TIME_DUMP = {
    "place_uid": "TEST_PLACE_UID",
    "daily_or_monthly": "D",
    results: [
        {
            zone_uid: 'a1b2c3d4e5f6g7h8i9j0', // 무작위 해시값
            zone_name: 'WELCOME ZONE',
            average_zone_residence_time: 3600000, // 1시간
        },
        {
            zone_uid: 'h9i8g7f6e5d4c3b2a1j0', // 무작위 해시값
            zone_name: 'ENTRANCE ZONE',
            average_zone_residence_time: 5400000, // 1시간 30분
        },
        {
            zone_uid: 'k1l2m3n4o5p6q7r8s9t0', // 무작위 해시값
            zone_name: 'CASPER ZONE',
            average_zone_residence_time: 7200000, // 2시간
        },
        {
            zone_uid: 't9s8r7q6p5o4n3m2l1k0', // 무작위 해시값
            zone_name: 'CASPER CONNECT ZONE',
            average_zone_residence_time: 1800000, // 30분
        },
        {
            zone_uid: 'z1y2x3w4v5u6t7s8r9q0', // 무작위 해시값
            zone_name: 'EV ZONE',
            average_zone_residence_time: 2400000, // 40분
        },
        {
            zone_uid: 'm1n2o3p4q5r6s7t8u9v0', // 무작위 해시값
            zone_name: 'COUNSELING ZONE',
            average_zone_residence_time: 3000000, // 50분
        }
    ]
}

const VISITOR_AGE_DUMP = {
    "place_uid": "TEST_PLACE_UID",
    "daily_or_monthly": "D",
    "results": [
        { date: '20241001', below_20_count: 15, twenty_count: 30, thirty_count: 25, fourty_count: 20, fifty_count: 10, over_60_count: 5 },
        { date: '20241002', below_20_count: 10, twenty_count: 28, thirty_count: 22, fourty_count: 18, fifty_count: 12, over_60_count: 6 },
        { date: '20241003', below_20_count: 12, twenty_count: 26, thirty_count: 30, fourty_count: 15, fifty_count: 9, over_60_count: 7 },
        { date: '20241004', below_20_count: 20, twenty_count: 30, thirty_count: 25, fourty_count: 20, fifty_count: 14, over_60_count: 8 },
        { date: '20241005', below_20_count: 18, twenty_count: 27, thirty_count: 20, fourty_count: 16, fifty_count: 11, over_60_count: 5 },
        { date: '20241006', below_20_count: 12, twenty_count: 24, thirty_count: 22, fourty_count: 19, fifty_count: 15, over_60_count: 4 },
        { date: '20241007', below_20_count: 14, twenty_count: 29, thirty_count: 21, fourty_count: 17, fifty_count: 10, over_60_count: 3 },
        { date: '20241008', below_20_count: 16, twenty_count: 31, thirty_count: 29, fourty_count: 22, fifty_count: 13, over_60_count: 6 },
        { date: '20241009', below_20_count: 19, twenty_count: 28, thirty_count: 25, fourty_count: 20, fifty_count: 12, over_60_count: 5 },
        { date: '20241010', below_20_count: 11, twenty_count: 26, thirty_count: 24, fourty_count: 18, fifty_count: 10, over_60_count: 7 },
        { date: '20241011', below_20_count: 15, twenty_count: 32, thirty_count: 27, fourty_count: 21, fifty_count: 13, over_60_count: 4 },
        { date: '20241012', below_20_count: 14, twenty_count: 25, thirty_count: 23, fourty_count: 19, fifty_count: 9, over_60_count: 6 },
        { date: '20241013', below_20_count: 13, twenty_count: 29, thirty_count: 21, fourty_count: 20, fifty_count: 12, over_60_count: 8 },
        { date: '20241014', below_20_count: 10, twenty_count: 30, thirty_count: 26, fourty_count: 22, fifty_count: 11, over_60_count: 5 },
        { date: '20241015', below_20_count: 12, twenty_count: 27, thirty_count: 24, fourty_count: 18, fifty_count: 14, over_60_count: 3 },
        { date: '20241016', below_20_count: 16, twenty_count: 30, thirty_count: 25, fourty_count: 21, fifty_count: 10, over_60_count: 2 },
        { date: '20241017', below_20_count: 14, twenty_count: 28, thirty_count: 23, fourty_count: 19, fifty_count: 12, over_60_count: 6 },
        { date: '20241018', below_20_count: 15, twenty_count: 31, thirty_count: 22, fourty_count: 20, fifty_count: 13, over_60_count: 5 },
        { date: '20241019', below_20_count: 10, twenty_count: 26, thirty_count: 20, fourty_count: 17, fifty_count: 11, over_60_count: 4 },
        { date: '20241020', below_20_count: 12, twenty_count: 29, thirty_count: 25, fourty_count: 21, fifty_count: 14, over_60_count: 3 },
        { date: '20241021', below_20_count: 18, twenty_count: 30, thirty_count: 24, fourty_count: 19, fifty_count: 10, over_60_count: 6 },
        { date: '20241022', below_20_count: 20, twenty_count: 32, thirty_count: 28, fourty_count: 22, fifty_count: 12, over_60_count: 5 },
        { date: '20241023', below_20_count: 15, twenty_count: 27, thirty_count: 23, fourty_count: 20, fifty_count: 13, over_60_count: 4 },
        { date: '20241024', below_20_count: 14, twenty_count: 28, thirty_count: 25, fourty_count: 21, fifty_count: 11, over_60_count: 3 },
        { date: '20241025', below_20_count: 12, twenty_count: 30, thirty_count: 22, fourty_count: 19, fifty_count: 10, over_60_count: 5 },
        { date: '20241026', below_20_count: 10, twenty_count: 29, thirty_count: 27, fourty_count: 20, fifty_count: 12, over_60_count: 6 },
        { date: '20241027', below_20_count: 15, twenty_count: 30, thirty_count: 24, fourty_count: 22, fifty_count: 14, over_60_count: 4 },
        { date: '20241028', below_20_count: 18, twenty_count: 31, thirty_count: 25, fourty_count: 21, fifty_count: 13, over_60_count: 5 },
        { date: '20241029', below_20_count: 14, twenty_count: 28, thirty_count: 23, fourty_count: 19, fifty_count: 9, over_60_count: 3 },
        { date: '20241030', below_20_count: 12, twenty_count: 26, thirty_count: 20, fourty_count: 18, fifty_count: 11, over_60_count: 6 },
        { date: '20241031', below_20_count: 10, twenty_count: 30, thirty_count: 24, fourty_count: 20, fifty_count: 12, over_60_count: 5 },
    ]
}

function Analyze(){
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [selectGender, setSelectGender] = useState<number | null>(null);
    const genderList = ['남성', '여성'];
    const [selectAge, setSelectAge] = useState<number | null>(null);
    const ageList = ['All', '20', '30', '40', '50'];
    const [selectZone, setSelectZone] = useState<number | null>(null);
    const zoneList = ['All', 'WELCOME ZONE', 'ENTRANCE ZONE', 'CASPER ZONE', 'CASPER CONNECT ZONE', 'EV ZONE', 'COUNSELING ZONE'];

    //IFIA-40
    const {data: requestVisitorAgesCountData, mutate: requestVisitorAgesCount, isPending: requestVisitorAgesCountIsPending} = useMutation({
        mutationFn: postVisitPeriodsAge,
        onSuccess(data: TpostVisitPeriodsAgeResponse, variables, context) {
            if(data.results.length === 0){
                if(startDate === null || endDate === null) return
                const started = convertDateFormat(startDate);
                const ended = convertDateFormat(endDate);
                requestVisitorAgesCount({
                    "place_uid": "TEST_PLACE_UID",
                    "daily_or_monthly": "D",
                    "start_date":started, 
                    "end_date":ended,
                })
            }
        },
        onError(error, variables, context) {
            console.error(error)
        },
    })

    //IFIA-60
    const {data: requestAverageStayTimeData, mutate: requestAverageStayTime, isPending: requestAverageStayTimeIsPending} = useMutation({
        mutationFn: getAverageStayTime,
        onSuccess(data: TgetAverageStayTimeResponse, variables, context) {
            if(data.results.length === 0){
                if(startDate === null || endDate === null) return
                const started = convertDateFormat(startDate);
                const ended = convertDateFormat(endDate);
                requestAverageStayTime({
                    "place_uid": "TEST_PLACE_UID",
                    "daily_or_monthly": "D",
                    "start_date":started, 
                    "end_date":ended,
                })
            }
        },
        onError(error, variables, context) {
            console.error(error)
        },
    })

    //IFIA-120
    const {data: requestZoneResidenceTimeData, mutate: requestZoneResidenceTime, isPending: requestZoneResidenceTimeIsPending} = useMutation({
        mutationFn: postZoneResidenceTime,
        onSuccess(data: TzoneResidenceTimeResponse, variables, context) {
            if(data.results.length === 0){
                if(startDate === null || endDate === null) return
                const started = convertDateFormat(startDate);
                const ended = convertDateFormat(endDate);
                requestZoneResidenceTime({
                    "place_uid": "TEST_PLACE_UID",
                    "daily_or_monthly": "D",
                    "start_date":started, 
                    "end_date":ended,
                })
            }
        },
        onError(error, variables, context) {
            console.error(error)
        },
    })

    useEffect(() => {
        if(startDate === null || endDate === null) return
        const started = convertDateFormat(startDate);
        const ended = convertDateFormat(endDate);
        
        requestVisitorAgesCount({
            "place_uid": "TEST_PLACE_UID",
            "daily_or_monthly": "D",
            "start_date":started, 
            "end_date":ended,
        })

        requestAverageStayTime({
            "place_uid": "TEST_PLACE_UID",
            "daily_or_monthly": "D",
            "start_date":started, 
            "end_date":ended,
        })

        requestZoneResidenceTime({
            "place_uid": "TEST_PLACE_UID",
            "daily_or_monthly": "D",
            "start_date":started, 
            "end_date":ended,
        })
    }, [startDate, endDate])

    const downloadToExcelFile = () => {
        if(!startDate || !endDate) return
        // Excel 파일 생성 및 다운로드
        const wb = XLSX.utils.book_new();
        const rowHeaderStyle = { 
            font: { bold: true, color: { rgb: 'FFFFFF' }, sz: 10 }, 
            fill: { fgColor: { rgb: '777F8C' } }, 
            alignment: { horizontal: 'center', vertical: 'center' }, 
            border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } } 
        };
        const columnHeaderStyle = { 
            font: { bold: true, color: { rgb: '000000' }, sz: 10 }, 
            fill: { fgColor: { rgb: 'E3DCD4' } }, 
            alignment: { horizontal: 'center', vertical: 'center' }, 
            border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } } 
        };
        const dataStyle = { 
            font: { color: { rgb: '000000' }, sz: 10 }, 
            fill: { fgColor: { rgb: 'FFFFFF' } }, 
            alignment: { horizontal: 'center', vertical: 'center' }, 
            border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } } 
        };
    
        // 열의 폭을 정의
        const colWidths = [250, 887];
    
        // cols 속성을 사용하여 각 열의 폭을 조절
        const cols = colWidths.map(width => ({ wpx: width }));

        const started = `${startDate.getFullYear()}.${(startDate.getMonth() + 1) < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)}.${(startDate.getDate()) < 10 ? '0' + (startDate.getDate()) : (startDate.getDate())}.`
        const ended = `${endDate.getFullYear()}.${(endDate.getMonth() + 1) < 10 ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)}.${(endDate.getDate()) < 10 ? '0' + (endDate.getDate()) : (endDate.getDate())}.`
        
        const headerRow = [
            { v: 'ZONE / DATE', t: 's', s: rowHeaderStyle },
            { v: `${started} ~ ${ended}`, t: 's', s: rowHeaderStyle },
        ];

        const columnHeaderTitle: string[] = []
        const columnData: string[] = [];

        RESIDENCE_TIME_DUMP.results.forEach((item, index) => {
            columnHeaderTitle.push(item.zone_name)
            const { hours, minutes} = convertMilliseconds(item.average_zone_residence_time)
            columnData.push(`${convertToMinutes(hours, minutes)} 분`)
        })
        
        const dataRows = columnHeaderTitle.map((item, index) => {
            const headerRow = { v: item, t: 's', s: columnHeaderStyle };
            return [headerRow, { v: columnData[index], t: 's', s: dataStyle }]
        });
    
        const rows = [headerRow, ...dataRows];
    
        // 새로운 Sheet 객체 생성
        const ws = XLSX.utils.aoa_to_sheet(rows);
    
        // cols 속성 적용
        ws['!cols'] = cols;
    
        // workbook에 추가
        XLSX.utils.book_append_sheet(wb, ws, '구역별 방문자 체류시간');

        const date = new Date();
        const today = `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`
    
        // 파일 다운로드
        XLSX.writeFile(wb, `${started}~${ended}_구역별 방문자 체류시간.xlsx`);
    }

    return(
        <>
        <GlobalNavigationBar />
        <main className={styles.main_root}>
            <div className={styles.main_body}>
                <div className={styles.header}>
                    <div className={styles.title_wrapper}>
                        <div className={styles.title}>
                            <span>분석</span>
                        </div>
                    </div>
                    <div className={styles.brand}>
                        <span>CASPER SONGPA STUDIO</span>
                    </div>
                </div>
                <div className={styles.combobox_container}>
                    <div className={styles.select_option_wrapper}>
                        <div className={styles.title}>
                            <span>{'기간 >'}</span>
                        </div>
                        <div style={{display: 'flex', gap: 16}}>
                            <div style={{width: 155}}>
                                <CustomDatePicker
                                    date={startDate}
                                    setDate={setStartDate}
                                    option={{
                                        endDate: endDate ? endDate : undefined,
                                        placeholderText: '날짜 선택'
                                    }}
                                />
                            </div>
                            <div style={{width: 155}}>
                                <CustomDatePicker
                                    date={endDate}
                                    setDate={setEndDate}
                                    option={{
                                        startDate: startDate ? startDate : undefined,
                                        placeholderText: '날짜 선택'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.select_option_wrapper}>
                        <div className={styles.title}>
                            <span>{'성별 >'}</span>
                        </div>
                        <div>
                            <ComboBox 
                            styleOption={
                                {
                                    width: '256px'
                                }
                            }
                            activeIndex={selectGender}
                            setActiveIndex={setSelectGender}
                            placeHolder='성별 선택' 
                            selectList={genderList} />
                        </div>
                    </div>
                    <div className={styles.select_option_wrapper}>
                        <div className={styles.title}>
                            <span>{'연령대 >'}</span>
                        </div>
                        <div>
                            <ComboBox 
                            styleOption={
                                {
                                    width: '247px'
                                }
                            }
                            activeIndex={selectAge}
                            setActiveIndex={setSelectAge}
                            placeHolder='연령대 선택' 
                            selectList={ageList} />
                        </div>
                    </div>
                    <div className={styles.select_option_wrapper}>
                        <div className={styles.title}>
                            <span>{'구역 >'}</span>
                        </div>
                        <div>
                            <ComboBox 
                            styleOption={
                                {
                                    width: '272px'
                                }
                            }
                            activeIndex={selectZone}
                            setActiveIndex={setSelectZone}
                            placeHolder='구역 선택' 
                            selectList={zoneList} />
                        </div>
                    </div>
                </div>
                <section className={styles.chart_section}>
                    <div className={styles.horizontal_section}>
                        <div className={styles.chart_wrapper}>
                            <span>{'방문자 수 변화 추이 >'}</span>
                            <p>Count / Date</p>
                            {
                                VISITOR_AGE_DUMP ?
                                    <LineChart visitorData={VISITOR_AGE_DUMP} />
                                : null
                            }
                        </div>
                    </div>
                    <div className={styles.horizontal_section}>
                        <div className={styles.chart_wrapper}>
                            <span>{'연령대별 방문자 분포 >'}</span>
                            <p>Count / Date</p>
                            {
                                VISITOR_AGE_DUMP ?
                                    <BarChart visitorData={VISITOR_AGE_DUMP} />
                                : null
                            }
                        </div>
                    </div>
                    <div className={styles.horizontal_section}>
                        <div className={styles.chart_wrapper}>
                            <span>{'성별별 방문자 분포 >'}</span>
                            <p>Count / Date</p>
                            {
                                VISITOR_GENDER_DUMP ?
                                    <GenderBarChart visitorData={VISITOR_GENDER_DUMP} />
                                : null
                            }
                        </div>
                    </div>
                </section>
                <section className={styles.data_table_section}>
                    <div className={styles.header}>
                        <div className={styles.title}>
                            <span>{'구역별 방문자 체류시간 >'}</span>
                        </div>
                        <button onClick={() => downloadToExcelFile()} className={styles.excel_download_btn}>엑셀 다운로드</button>
                    </div>
                    <div className={styles.table_wrapper}>
                        {
                            RESIDENCE_TIME_DUMP ? 
                                <VisitorResidenceTimeTable 
                                    startDate={startDate}
                                    endDate={endDate}
                                    residenceTimeData={RESIDENCE_TIME_DUMP}/>
                            : null
                        }
                    </div>
                </section>
            </div>
        </main>
        </>
    )
}

export default Analyze;