/**
 * Converts milliscond data to hour, minute, and second.
 * @param {number} ms - milliscond
 */
const convertMilliseconds = (ms:number) => {
    const hours = Math.floor(ms / 3600000); // 1시간 = 3,600,000 ms
    const minutes = Math.floor((ms % 3600000) / 60000); // 1분 = 60,000 ms
    const seconds = Math.floor((ms % 60000) / 1000); // 1초 = 1,000 ms

    return { hours, minutes, seconds };
}
export default convertMilliseconds;