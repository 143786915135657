import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './CustomDatePicker.module.css';

type Tprops = {
    date: Date | null;
    setDate: React.Dispatch<React.SetStateAction<Date | null>>
    option?:{
        startDate?: Date;
        endDate?: Date;
        placeholderText?: string;
        mode?: 'year' | 'month';
    }
    onSelectHandler?: () => void,
}

function CustomDatePicker({date, setDate, option, onSelectHandler}: Tprops){

    return (
        <DatePicker 
            dateFormat={option?.mode === 'year' ? "yyyy" : option?.mode === 'month' ? 'MM' : "yyyyMMdd"}
            shouldCloseOnSelect 
            showYearPicker={option?.mode === 'year' ? true : false}
            showMonthYearPicker={option?.mode === 'month' ? true : false}
            minDate={option?.startDate ? option.startDate : undefined} 
            maxDate={option?.endDate ? option.endDate : undefined} 
            selected={date}
            onSelect={onSelectHandler}
            onChange={(date: any) => setDate(date)}
            placeholderText={option?.placeholderText ? option.placeholderText : undefined}
            popperClassName={styles.calendar}
            customInput={
                <input className={styles.custom_input} />
            }
        />
    )
}

export default CustomDatePicker;