import React, { useState } from 'react';
import styles from './LoginForm.module.css';
import { HyunDaiLogo, EmailLogo, PasswordLogo, EyeClose, EyeOpen, RemoveLogo } from 'assets/svgs';

function LoginForm(){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSecure, setIsSecure] = useState(true);
    const [focusedEmail, setFocusedEmail] = useState(false);
    const [focusedPassword, setFocusedPassword] = useState(false);
    const [isError, setIsError] = useState(false);

    return (
        <div className={styles.login_form_root}>
            <div className={styles.title_container}>
                <div className={styles.logo_wrapper}>
                    <HyunDaiLogo />
                </div>
                <div className={styles.horizontal_line} />
                <span>CASPER STUDIO</span>
            </div>
            <div className={styles.input_container}>
                <div className={`${styles.input_wrapper} ${focusedEmail ? styles.active : ''}`}>
                    <div>
                        <EmailLogo />
                    </div>
                    <input  
                        onFocus={(event: React.FocusEvent<HTMLInputElement>) => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                        type="email"
                        placeholder='이메일을 입력해 주세요.'
                        value={email}
                     />
                </div>

                <div className={`${styles.input_wrapper} ${focusedPassword ? styles.active : ''}`}>
                    <div>
                        <PasswordLogo />
                    </div>
                    <input 
                        onFocus={(event: React.FocusEvent<HTMLInputElement>) => setFocusedPassword(true)}
                        onBlur={() => setFocusedPassword(false)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        type={isSecure ? "password" : "text"} 
                        placeholder='비밀번호를 입력해 주세요.'
                        value={password}  />
                    <div className={styles.password_input_btn_container}>
                        {
                            password ? 
                            <div onClick={() => setPassword(() => '')}>
                                <RemoveLogo />
                            </div> 
                            : null
                        }
                        <div
                            onClick={() => setIsSecure(prev => !prev)}
                            className={styles.eye_logo}>
                        {
                            isSecure ? 
                            <EyeClose width={22} height={19} />
                            :
                            <EyeOpen width={22} height={19} />
                        }
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.error_msg_container}>
                {
                    isError ?
                    <div className={styles.error_box}>
                        <span>*이메일 혹은 비밀번호가 일치하지 않습니다. 입력한 내용을 확인해 주세요.</span>
                    </div>
                    : null
                }
            </div>
            <div className={styles.btn_container}>
                <button disabled={!email || !password ? true : false} className={styles.login_btn}>로그인</button>
            </div>
            <div className={styles.copyright_container}>
                <span>COPYRIGHT ⓒ HYUNDAI MOTOR COMPANY. ALL RIGHTS RESERVED.</span>
            </div>
            
        </div>
    )
}

export default LoginForm;