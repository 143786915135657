/**
 * Converts hour, minute data to minute.
 * @param {number} hours 
 * @param {number} minutes 
 * @returns 
 */
function convertToMinutes(hours: number, minutes: number) {
    return (hours * 60) + minutes;
}

export default convertToMinutes;