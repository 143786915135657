import React, { useEffect, useRef } from 'react';
import styles from './AlertModal.module.css';
import { useRecoilState } from 'recoil';
import alertModalState, { initializeAlertModalState } from 'stores/alertModalState';

function AlertModal(){
    const modalBodyRef = useRef<HTMLDivElement>(null);
    const [thisModalState, setThisModalState] = useRecoilState(alertModalState);

    const modalClose = () => {
        setTimeout(() => {
            setThisModalState(initializeAlertModalState);
        }, 150)
        if(!modalBodyRef.current) return
        const keyframes = [
            { opacity: 1, top: "50%" },
            { opacity: 0, top: "45%" },
        ]
        const animateOptions = {
            duration: 250
        }
        modalBodyRef.current.animate(keyframes, animateOptions)
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        }
    }, [])

    return(
        <div className={styles.alert_modal_root}>
            <div className={styles.modal_wrapper}>
                <div className={styles.modal_background}></div>
                <div ref={modalBodyRef} className={styles.modal_body}>
                    <div className={styles.alert_text_container}>
                        <span>{thisModalState.alertText}</span>
                    </div>
                    <div className={styles.btn_container}>
                        <button onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.preventDefault();

                            modalClose()
                        }}>{thisModalState.buttonText}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertModal;