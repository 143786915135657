import React, { useEffect, useRef, useState } from 'react';
import styles from './UserTracking.module.css';
import { ChartTooltip, ComboBox, GlobalNavigationBar, InputBox } from 'components/public';
import { ZoneMapSrc } from 'assets/images';
import { useRecoilState } from 'recoil';
import { alertModalState } from 'stores';
import { convertMilliseconds, convertToMinutes } from 'utils';
import { useQuery } from '@tanstack/react-query';
import { getUserTracking } from 'queries';

const CUSTOMER_RESIDENCE_TIME_DUMP = {
    place_uid: "TEST_PLACE_UID",
    daily_or_monthly: "D",
    cust_no: "01012341234",
    results: [
        {
            zone_uid: 'a1b2c3d4e5f6g7h8i9j0', // 무작위 해시값
            zone_name: 'WELCOME ZONE',
            average_zone_residence_time: 3600000, // 1시간
        },
        {
            zone_uid: 'h9i8g7f6e5d4c3b2a1j0', // 무작위 해시값
            zone_name: 'ENTRANCE ZONE',
            average_zone_residence_time: 5400000, // 1시간 30분
        },
        {
            zone_uid: 'k1l2m3n4o5p6q7r8s9t0', // 무작위 해시값
            zone_name: 'CASPER ZONE',
            average_zone_residence_time: 7200000, // 2시간
        },
        {
            zone_uid: 't9s8r7q6p5o4n3m2l1k0', // 무작위 해시값
            zone_name: 'CASPER CONNECT ZONE',
            average_zone_residence_time: 1800000, // 30분
        },
        {
            zone_uid: 'z1y2x3w4v5u6t7s8r9q0', // 무작위 해시값
            zone_name: 'EV ZONE',
            average_zone_residence_time: 2400000, // 40분
        },
        {
            zone_uid: 'm1n2o3p4q5r6s7t8u9v0', // 무작위 해시값
            zone_name: 'COUNSELING ZONE',
            average_zone_residence_time: 3000000, // 50분
        }
    ]
}

function UserTracking(){
    
    const [visitNumber, setVisitNumber] = useState('');
    const [selectZone, setSelectZone] = useState<number | null>(null);
    const zoneList = ['All', 'WELCOME ZONE', 'ENTRANCE ZONE', 'CASPER ZONE', 'CASPER CONNECT ZONE', 'EV ZONE', 'COUNSELING ZONE'];
    const [, setAlertModal] = useRecoilState(alertModalState);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    interface Tooltip {
        id: string;
        title: string;
        dataText: string;
        dataType: string;
    }
    const [tooltip, setTooltip] = useState<Tooltip | null>(null);
    const [mouseCoord, setMouseCoord] = useState({x: 0, y: 0});

    const {data, isPending, refetch, isLoading} = useQuery({
        queryKey: [visitNumber],
        queryFn: () => getUserTracking({
            startDate: '20241001',
            endDate: '20241031',
            placeUid: 'TEST_PLACE_UID',
            customerNumber: visitNumber
        }),
        enabled: false
    })
    
    useEffect(() => {
        if(!visitNumber) return
        if(selectZone) return
        
        if(!data){
            setAlertModal({
                isOpen: true,
                alertText: '고객 전화번호가 존재하지 않습니다.',
                buttonText: '확인'
            })
            setVisitNumber('')
            setSelectZone(null)
        }
    }, [data, isPending, isLoading])
    console.log('data', data);
    return (
        <>
        <GlobalNavigationBar />
        <main className={styles.main_root}>
            <div className={styles.main_body}>
                <div className={styles.header}>
                    <div className={styles.title_wrapper}>
                        <div className={styles.title}>
                            <span>구역 정보</span>
                        </div>
                    </div>
                    <div className={styles.brand}>
                        <span>CASPER SONGPA STUDIO</span>
                    </div>
                </div>
                <div className={styles.combobox_container}>
                    <div className={styles.select_container}>
                        <div className={styles.select_option_wrapper}>
                            <div className={styles.title}>
                                <span>{'고객번호 선택 >'}</span>
                            </div>
                            <div style={{width: 256}}>
                                <InputBox 
                                    placeholder='고객번호 입력'
                                    type='number'
                                    value={visitNumber}
                                    onChangeHandler={(event: React.ChangeEvent<HTMLInputElement>) => setVisitNumber(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.select_option_wrapper}>
                            <div className={styles.title}>
                                <span>{'구역 >'}</span>
                            </div>
                            <div>
                                <ComboBox 
                                styleOption={
                                    {
                                        width: '272px'
                                    }
                                }
                                activeIndex={selectZone}
                                setActiveIndex={setSelectZone}
                                placeHolder='구역 선택' 
                                selectList={zoneList} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.btn_container}>
                        <button onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.preventDefault();
                            if(!visitNumber) {
                                setAlertModal({
                                    isOpen: true,
                                    alertText: '고객 번호를 입력해주세요.',
                                    buttonText: '확인'
                                })
                                return
                            }
                            if(selectZone === null) {
                                setAlertModal({
                                    isOpen: true,
                                    alertText: '확인하실 구역을 선택해주세요.',
                                    buttonText: '확인'
                                })
                                return 
                            }

                            refetch();
                        }}>적용</button>
                    </div>
                </div>
                <section className={styles.zone_section}>
                    <div className={styles.tracking_wrapper}>
                        <img className={styles.main_img} width={1095} height={579} src={ZoneMapSrc} alt="zonemap" />
                        {
                            CUSTOMER_RESIDENCE_TIME_DUMP && CUSTOMER_RESIDENCE_TIME_DUMP.results ? 
                            CUSTOMER_RESIDENCE_TIME_DUMP.results.map((item, index) => {
                                interface Zones {
                                    [key: string]: string | undefined;
                                }
                                
                                const zoneStyles: Zones = {
                                    "WELCOME ZONE": styles.welcome_zone,
                                    "ENTRANCE ZONE": styles.entrance_zone,
                                    "CASPER ZONE": styles.casper_zone,
                                    "CASPER CONNECT ZONE": styles.casper_connect_zone,
                                    "EV ZONE": styles.ev_zone,
                                    "COUNSELING ZONE": styles.counseling_zone
                                };

                                const {hours, minutes} = convertMilliseconds(item.average_zone_residence_time);
                                const convertMinute = convertToMinutes(hours, minutes);
                                
                                let minuteStyle: any;
                                if(convertMinute >= 51){
                                    minuteStyle = styles.tracking_6
                                } else if(convertMinute >= 41){
                                    minuteStyle = styles.tracking_5
                                } else if(convertMinute >= 31){
                                    minuteStyle = styles.tracking_4
                                } else if(convertMinute >= 21){
                                    minuteStyle = styles.tracking_3
                                } else if(convertMinute >= 11){
                                    minuteStyle = styles.tracking_2
                                } else {
                                    minuteStyle = styles.tracking_1
                                }
                                const thisStyle = zoneStyles[item.zone_name];
                                return (
                                    <div
                                        key={item.zone_uid} 
                                        onMouseMove={(event: React.MouseEvent<HTMLDivElement>) => {
                                            const element = tooltipRef.current;
                                            if(!element) return
                                            setMouseCoord({x: event.clientX - (element.clientWidth / 2), y: event.clientY - element.clientHeight - 15});
                                        }}
                                        onMouseOver={(event: React.MouseEvent<HTMLDivElement>) => setTooltip({id: 'zone-' + item.zone_name, dataText: convertMinute.toString(), dataType: '분', title: item.zone_name})}
                                        onMouseOut={() => setTooltip(null)}
                                        className={`${styles.tracking_element_wrapper} ${minuteStyle || ''} ${thisStyle || ''}`}>
                                        <div className={`${styles.tracking_element}`}>
                                            <span>{item.zone_name}</span>
                                        </div>
                                    </div>
                                )
                            })
                            : null
                        }
                    </div>
                </section>
            </div>
            {tooltip ? 
                <div ref={tooltipRef} 
                    style={{zIndex: 30, position: 'fixed', top: mouseCoord.y, left: mouseCoord.x}}>
                        <ChartTooltip title={tooltip.title} dataText={tooltip.dataText} dataType={tooltip.dataType} />
                </div>
            : null}
        </main>
        </>
    )
}

export default UserTracking;