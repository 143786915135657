import useAuth from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type TProps = {
    children: React.ReactNode;
}

function AuthProvider({children}: TProps){
    const navigate = useNavigate();
    const { isAuthentication, signIn, signOut, getLocalToken } = useAuth();

    // useEffect(() => {
    //     if(isAuthentication){
    //         navigate('/dashboard');
    //     } else {
    //         navigate('/login');
    //     }
    // }, [isAuthentication])

    return (
        <>
        {children}
        </>
    )
}

export default AuthProvider