const API_URL = process.env.REACT_APP_API_URL;
const API_PORT = process.env.REACT_APP_API_PORT;

export interface TpostVisitPeriodsAge {
    place_uid: string;
    daily_or_monthly: string;
    start_date: string;
    end_date: string;
}

/**
 * IFIA_0050 - 특정 전시장의 기간별,성별별 방문자 수
 * @returns Promise<any>
 */
async function postVisitPeriodsAge({place_uid, daily_or_monthly, start_date, end_date}: TpostVisitPeriodsAge){   
    const ACCESSTOKEN = process.env.REACT_APP_TEST_TOKEN;
    if(!ACCESSTOKEN){
        throw new Error('AccessToken is not defined');
    }
    
    const data = {
        "place_uid": place_uid,
        "daily_or_monthly": daily_or_monthly, // "M" 일때 월별
        "start_date": start_date, // "M" 일때 일생략  필수
        "end_date": end_date // "M" 일때 일 생략 필수
    }

    const response = await fetch(`${API_URL}:${API_PORT}/api/v1/places/${place_uid}/visits/periods/ages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${ACCESSTOKEN}`
        },
        body: JSON.stringify(data),
      });
      
    return response.json();
}

export default postVisitPeriodsAge;