import React from 'react';
import styles from './Login.module.css';
import { LoginForm } from 'components/login';

function Login(){

    return (
        <main className={styles.main_root}>
            <LoginForm />
        </main>
    )
}

export default Login;